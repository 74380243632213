import type { ReactElement, ReactNode } from "react";
import { useCallback, useState } from "react";
import { useTransition, a } from "react-spring";

import type { ToastOptions } from "../ToastContext";
import { ToastContext } from "../ToastContext";
import { ToastController } from "../ToastController";
import type { ToastType } from "../toastTypes";

import * as styles from "./Toaster.styles";

let id = 0;

const MAX_NUMBER_TOASTS = 3;

interface Props {
  children?: ReactNode;
}

type Item = {
  key: string | number;
  type: ToastType;
  content: ReactElement;
  options: ToastOptions;
};

const Toaster = ({ children }: Props) => {
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState<Item[]>([]);

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0 },
    enter: item => async next =>
      await next({ opacity: 1, height: refMap.get(item).offsetHeight + 8 }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    keys: (item: Item): Item["key"] => item.key,
  });

  const makeToast = useCallback(
    (type: ToastType, content: ReactElement, options?: ToastOptions) => {
      return setItems(state => {
        if (state.length > MAX_NUMBER_TOASTS) return state;
        const uniqID = options?.toastId ? options.toastId : id++;

        return [...state, { key: uniqID, type, content, options }];
      });
    },
    []
  );

  const doCallBack = key => {
    items.filter(item => item.key === key).forEach(item => item.options?.onClose?.());
  };

  const timeoutToast = key => () => {
    doCallBack(key);
    setItems(state => state.filter(i => i.key !== key));
  };

  const removeToast = key => {
    doCallBack(key);
    setItems(state => state.filter(i => i.key !== key));
  };

  const renderToasts = () => (
    <div css={styles.base}>
      {transitions((style, item) => (
        <a.div style={style}>
          <div ref={ref => ref && refMap.set(item, ref)}>
            <ToastController
              type={item.type ? item.type : null}
              autoDismiss={item.options?.autoDismiss}
              autoDismissTimeout={item.options?.autoDismissTimeout}
              onDismiss={timeoutToast(item.key)}
              isCloseable={item.options?.isCloseable}
            >
              {item.content}
            </ToastController>
          </div>
        </a.div>
      ))}
    </div>
  );

  return (
    <ToastContext.Provider
      value={{
        makeToast,
        removeToast,
      }}
    >
      {renderToasts()}
      {children}
    </ToastContext.Provider>
  );
};

export { Toaster };
